<template>
  <div class="mb-4 hidden max-[992px]:flex flex-wrap gap-x-4 gap-y-2 ">
    <template v-for="option in props.options">
      <div v-if="option" :key="option.value" class="flex-nowrap rounded bg-[#F2F2F2] px-[1rem] py-[0.5rem] text-[0.875rem]" :class="{ active: isActive(option.value) }" @click="updateListingFilter(option.value)">
        <a>{{ option.label }}</a>
      </div>
    </template>
  </div>
  <div class="max-[992px]:hidden grid grid-cols-1 gap-y-[0.75rem]">
    <template v-for="option in props.options">
      <div v-if="option" class="flex items-center justify-start space-x-[0.5rem]">
        <input :id="option.value" type="checkbox" @change="updateListingFilter(option.value)" />
        <label class="text-[0.875rem]" :for="option.value">{{ option.label }}</label>
      </div>
    </template>
  </div>
</template>

<script setup>
  import { useStore } from '@nanostores/vue';
  import { listingFilters, updateFilter } from '@store/listingFilterStore.js';

  const props = defineProps({
    filterKey: {
      default: null,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    }
  });

  const filters = useStore(listingFilters);

  const isActive = (value) => {
    const filterValues = filters.value[props.filterKey]?.values || [];
    return filterValues.includes(value);
  };

  const updateListingFilter = (value) => {
    updateFilter({ key: props.filterKey, value });
  };
</script>

<style scoped>
  div.active {
    background-color: black;
    color: white;
  }
</style>
